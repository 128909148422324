<template>
  <div class="landing-body-height position-relative d-block">
    <b-col cols="8" offset="2" xl="6" offset-xl="3" class="text-center">
      <welcome-message text-msg="Welcome to Mea Service Portal"/>
    </b-col>
    <b-col cols="10" offset="1" lg="6" offset-lg="3">
      <div v-if="!pageError && passwordForm">
        <welcome-message
          class="text-center"
          :sub-heading="true"
          :text-msg="welcomeMessage"/>
        <change-password v-if="!completeRegMessage" :full-version="false" :emit-result="true" @saveSetPassword="passwordSet"/>
        <mea-alert type="success" :timeoutSec="0" class="mt-2 text-center" v-else>
          {{ completeRegMessage }}
        </mea-alert>
      </div>
      <div class="text-center mt-5 text-mea-red" v-else>
        {{ pageError }}
      </div>
    </b-col>
  </div>
</template>

<script>
import WelcomeMessage from '@meawallet/msp-common/lib/components/MeaWelcomeMessage'
import ChangePassword from '@meawallet/msp-common/lib/components/MeaChangePassword/ChangePassword'
import MeaAlert from '@meawallet/msp-common/lib/components/MeaAlert'
import sessionService from '@meawallet/msp-common/lib/services/sessionService'
import logicService from '@meawallet/msp-common/lib/services/logicService'
import lpService from '../services/lpService'
import store from '../store/store'

export default {
  data () {
    return {
      invitationId: '',
      resetPasswordId: '',
      pageError: '',
      newPassword: '',
      confirmedPassword: '',
      completeRegMessage: '',
      passwordForm: false,
      routeName: ''
    }
  },
  computed: {
    welcomeMessage () {
      return this.routeName === 'reset-password' ? 'Reset Password' : 'User activation'
    }
  },
  methods: {
    passwordSet () {
      this.completeRegMessage = 'Your password has been set. In few seconds you will be redirected to the login page.'
      sessionService.clearUserData()
      setTimeout(() => {
        this.$router.push({ name: 'login' })
      }, 5000)
    },
    authInviteId (inviteId) {
      lpService.activateInvite(inviteId)
        .then(response => {
          if (!response || response.status !== 202) {
            this.pageError = 'Something went wrong, please try again.'
          } else {
            this.passwordForm = true
          }
        })
        .catch(error => {
          let errorData = error.response.data.error
          switch (errorData.code) {
            case 'VALIDATION_FAILED':
              this.pageError = 'Provided Invitation ID is incorrect. Please contact your system administrator.'
              break
            case 'INVITATION_EXPIRED':
              this.pageError = 'Your invitation link has expired or is incorrect. Please contact your system administrator.'
              break
            default:
              this.pageError = 'Something went wrong, please try again.'
              break
          }
        })
        .finally(() => {
          logicService.loadingOverlay(false)
        })
    },
    authPasswordChangeId (resetId) {
      lpService.activateResetPasswordId(resetId)
        .then(response => {
          if (!response) {
            this.pageError = 'Something went wrong, please try again.'
          }
          this.passwordForm = true
        })
        .catch(error => {
          let errorData = error.response.data.error
          switch (errorData.code) {
            case 'USER_PROFILE_NOT_FOUND':
              this.pageError = 'Provided ID for password change is incorrect. Please contact your system administrator.'
              break
            case 'RESET_LINK_EXPIRED':
              this.pageError = 'Your password link has expired. Please contact your system administrator.'
              break
            default:
              this.pageError = 'Something went wrong, please try again.'
              break
          }
        })
        .finally(() => {
          logicService.loadingOverlay(false)
        })
    }
  },
  mounted () {
    logicService.loadingOverlay()
    this.routeName = this.$router.currentRoute.name
    store.state.commonStore.tempAuth = true
    if (this.routeName === 'reset-password') {
      if (this.$route.params.resetId) {
        this.resetPasswordId = this.$route.params.resetId
        this.authPasswordChangeId(this.resetPasswordId)
      } else {
        this.pageError = 'Missing password ID to continue with password change.'
      }
    } else {
      if (this.$route.params.invitationId) {
        this.invitationId = this.$route.params.invitationId
        this.authInviteId(this.invitationId)
      } else {
        this.pageError = 'Missing invitation ID to continue registration.'
      }
    }
  },
  components: {
    WelcomeMessage,
    ChangePassword,
    MeaAlert
  }
}
</script>
