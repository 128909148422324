import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import vuexI18n from 'vuex-i18n'
import Vuelidate from 'vuelidate'
import store from './store/store'
import logicService from '@meawallet/msp-common/lib/services/logicService'

import '@meawallet/msp-common/lib/assets/fontAwesome/font-awesome'

import TranslationEn from '@meawallet/msp-common/lib/assets/i18n/en.json'
import TranslationRu from '@meawallet/msp-common/lib/assets/i18n/ru.json'
import TranslationLv from '@meawallet/msp-common/lib/assets/i18n/lv.json'

Vue.use(BootstrapVue)
Vue.use(vuexI18n.plugin, store)
Vue.use(Vuelidate)

Vue.i18n.add('en', TranslationEn)
Vue.i18n.add('lv', TranslationLv)
Vue.i18n.add('ru', TranslationRu)

// fallback will be called in case current language translation not found. Will be overwritten by default language
Vue.i18n.fallback(process.env.VUE_APP_DEFAULT_LOCALE)
Vue.i18n.set(process.env.VUE_APP_DEFAULT_LOCALE)

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.config.silent = true
}

(async () => {
  await axios.get(logicService.initConfigUrl())
    .then(res => {
      Vue.prototype.$config = res.data
      new Vue({
        router,
        store,
        render: (h) => h(App)
      }).$mount('#app')
    })
})()
