<template>
  <b-container fluid class="app-container p-0">
    <header-app class="row"/>
    <router-view />
    <footer-app class="row" :version-uri="versionUrl">
      <template v-slot> Landing: {{ componentVersion }} </template>
      <template v-slot:version>
        Dashboard: {{ componentVersion }}
      </template>
    </footer-app>
  </b-container>
</template>

<script>
import HeaderApp from '@meawallet/msp-common/lib/layout/Header'
import FooterApp from '@meawallet/msp-common/lib/layout/Footer'
import logicService from '@meawallet/msp-common/lib/services/logicService'
import { version } from '../package.json'

export default {
  data () {
    return {
      versionUrl: ''
    }
  },
  computed: {
    componentVersion () {
      return version
    }
  },
  beforeMount () {
    this.$store.commit('commonStore/setCurrentApp', process.env.VUE_APP_APPS_MSP_LANDING_PAGE)
    if (!logicService.isUserHaveAccessToApp()) {
      this.$router.push({ name: 'login' })
    }
  },
  mounted () {
    this.versionUrl = 'dashboard#' + this.componentVersion.split('.').join('')
  },
  components: {
    HeaderApp,
    FooterApp
  }
}
</script>

<style lang="scss">
@import "/assets/style/main";
</style>
