import Vue from 'vue'
import Vuex from 'vuex'
import store from './landing-page-store'
import commonStore from '@meawallet/msp-common/lib/store/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    store,
    commonStore
  }
})
