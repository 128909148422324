<template>
  <div class="landing-body-height position-relative d-block">
    <b-col cols="8" offset="2" xl="6" offset-xl="3" class="text-center">
      <welcome-message :text-msg="getMeaWelcomeText"/>
    </b-col>
    <b-col cols="10" offset="1" xl="8" offset-xl="2">
      <welcome-message class="text-center" :sub-heading="true" text-msg="My Apps"/>
      <b-card-group columns deck class="d-flex justify-content-around custom-bottom-padding mt-n4 smart-app-list">
        <loading :active="!isUserDataLoaded" :can-cancel="false" class="position-absolute"/>
        <template v-if="isUserDataLoaded">
          <smart-app
            v-for="app in availableApps"
            :key="app.id"
            :source="app"
            :id="'landing-page-app-' + app.name.toLowerCase()"
            showHeading
            :activeLink="app.isActive"
            class="mt-4"
            :app-class="{'no-border-radius align-top box-shadow': true, 'mea-disabled': !app.isActive }"
            :custom-bottom-text="!app.isActive ? appWrappingKeyError : ''"
            icon-size="large"/>
        </template>
      </b-card-group>
    </b-col>
  </div>
</template>

<script>
import Vue from 'vue'
import SmartApp from '@meawallet/msp-common/lib/components/MeaSmartApp'
import WelcomeMessage from '@meawallet/msp-common/lib/components/MeaWelcomeMessage'
import Loading from 'vue-loading-overlay'
import store from '@/store/store'

export default {
  data () {
    return {
      appWrappingKeyError: 'Invalid configuration for organization. \n Please contact support.'
    }
  },
  computed: {
    isUserDataLoaded () {
      return this.$store.state.commonStore.userInfo !== ''
    },
    getMeaWelcomeText () {
      return Vue.prototype.$config.customWelcomeText ? Vue.prototype.$config.customWelcomeText : 'Welcome to Mea Service Portal'
    },
    availableApps () {
      let availableApps = []
      let roles = this.$store.state.commonStore.tokenData.rls
      Object.keys(roles).forEach(key => {
        if (key === 'MSP-TOKEN-MANAGEMENT') {
          availableApps.push({
            isActive: !!Object.keys(store.state.commonStore.tmWrappingKey).length,
            name: key
          })
        } else {
          availableApps.push({ name: key, isActive: true })
        }
      })
      return availableApps
    }
  },
  components: {
    SmartApp,
    WelcomeMessage,
    Loading
  }
}
</script>
<style  lang="scss">
  .card-wrapper .custom-bottom-text {
    color: Crimson;
    text-align: center;
    span {
      white-space: pre-line;
    }
  }
</style>
<style lang="scss" scoped>
  @media (min-width: 1921px){
    // fix to avoid huge spacer between apps on big screens (MSP-177)
    .smart-app-list {
      width: 75%;
      margin: 0 auto;
    }
  }
  .landing-body-height {
    min-height: calc(100vh - #{$header-height});
    padding-bottom: $footer-height;
  }
</style>
