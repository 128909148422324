import Vue from 'vue'
import Router from 'vue-router'
import MyApps from '../views/MyApps'
import Registration from '../views/Registration'

import Login from '@meawallet/msp-common/lib/components/MeaLogin'
import Profile from '@meawallet/msp-common/lib/components/MeaProfile'
import Policy from '@meawallet/msp-common/lib/components/MeaPolicy'

import routerGuard from '@meawallet/msp-common/lib/services/routerGuard'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:locale/',
      redirect: { name: 'main' },
      beforeEnter: routerGuard.isAuthenticated
    },
    {
      path: '/:locale/main',
      name: 'main',
      component: MyApps,
      beforeEnter: routerGuard.isAuthenticated
    },
    {
      path: '/:locale/profile',
      name: 'profile',
      component: Profile,
      beforeEnter: routerGuard.isAuthenticated
    },
    {
      path: '/:locale/login',
      name: 'login',
      component: Login,
      beforeEnter: routerGuard.forceLogout
    },
    {
      path: '/:locale/registration/:invitationId?',
      name: 'registration',
      component: Registration,
      beforeEnter: routerGuard.forceLogout
    },
    {
      path: '/:locale/reset-password/:resetId?',
      name: 'reset-password',
      component: Registration,
      beforeEnter: routerGuard.forceLogout
    },
    {
      path: '/:locale/privacy-policy',
      name: 'privacy-policy',
      component: Policy
    }
  ]
})

router.beforeEach(routerGuard.beforeEachLogic(router))

export default router
