import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common['Pragma'] = 'no-cache'

export default {
  activateInvite (inviteId) {
    return axios({ method: 'get', url: 'users/activate/' + inviteId, baseURL: Vue.prototype.$config.apiUmsEndpoint })
      .then(response => {
        return response
      })
  },
  activateResetPasswordId (resetId) {
    return axios({ method: 'get', url: 'credentials/reset/' + resetId, baseURL: Vue.prototype.$config.apiUmsEndpoint })
      .then(response => {
        return response.status === 204
      })
  }
}
